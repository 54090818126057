/* eslint-disable import/no-anonymous-default-export */
export default {
  white: "#ffffff",
  white2: "#E0E0E0",
  primary1: "#E6F33F",
  primary2: "#FBFF22",
  gray1: "#19191E",
  gray2: "#272727",
  gray3: "#2E2E2E",
  gray4: "#4B4B4B33",
  gray5: "#363636",
  gray6: "#D9D9D9",
  gray7: "#3F3F4D",
  gray8: "#4c4c4d",
  yellow: "#F4FF6A",
  lightGray: "#BABABA",
  lightWhite: "#E0E0E0",
  blue: "rgb(33, 112, 208)",
  blue2: "blue",
};

// export default {
//   white: "#ffffff",
//   white2: "#E0E0E0",
//   primary1: "#957AFF",
//   primary2: "#6D73FF",
//   linearPrimary: "linear-gradient(43deg, #7055DB 27.08%, #5C97EF 100%)",
//   linearSecondary: "linear-gradient(42deg, #6B51D6 0%, #45A0D2 100%)",
//   linearBorderButton:
//     "linear-gradient(42deg, #BEADFF 0%, rgba(174, 168, 255, 0.00) 29.01%, rgba(163, 207, 255, 0.00) 71.71%, #9FDDFF 100%)",
//   subTitle: "#959595",
//   bgPrimary: "#000000",
//   bgShadow: "#000000b2",
//   black1: "#0C0B0D",
//   black2: "#18181A",
//   black3: "#212122",
//   border: "#FFFFFF26",
//   gray1: "#19191E",
//   gray2: "#272727",
//   gray3: "#2E2E2E",
//   gray4: "#4B4B4B33",
//   gray5: "#363636",
//   gray6: "#D9D9D9",
//   gray7: "#3F3F4D",
//   gray8: "#4c4c4d",
//   yellow: "#F4FF6A",
//   lightGray: "#BABABA",
//   lightWhite: "#E0E0E0",
//   red2: "#DC3030",
//   green2: "#37C071",
//   red3: "#FF3B2F",
//   green3: "#00FF6C",
//   colorSecondary: "#c1ce06b0",
//   colorWhite2: "#E0E0E0",
//   colorGreen: "#37C071",
//   colorRed: "#DC3030",
//   colorBG: "#0C0B0D",
//   colorLightGreen: "#E2FBE4",
//   colorLightRed: "#FFD7D7",
//   colorLightBlue: "#cfd2f7",
// };
